body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.fp-watermark {
  display: none !important;
  pointer-events: none !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.tips-dialog .ant-modal {
  max-width: 80%;
}

.ant-modal-close-x {
  font-size: 26rem !important;
}

.ant-form.ant-form-vertical .ant-form-item-label {
  padding-bottom: 8rem;
}
.ant-form-item .ant-form-item-label label {
  color: #222222;
  font-size: 24rem;
  height: 32rem;
  @apply md:text-[14rem];
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: #222222 !important;
}

.ant-btn.big-btn {
  height: 60rem;
  padding: 4rem 15rem;
  background: #22CF68 !important;
  border-color: #22CF68 !important;
  font-size: 18rem;
}
.ant-btn.big-btn[disabled], .ant-btn.big-btn[disabled]:active, .ant-btn.big-btn[disabled]:focus, .ant-btn.big-btn[disabled]:hover {
  color: rgba(0,0,0,.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-form-item .ant-input,
.ant-form-item .ant-picker {
  width: 100%;
  padding: 10rem 25rem !important;
  /* border-radius: 25rem !important; */
  background: transparent !important;
  border: 2px solid rgba(34, 35, 40, 0.1);
  color: #989898 !important;
  font-size: 20rem;
  outline: none !important;
  @apply h-[66rem] md:h-[50rem] md:text-[14rem];
}

.ant-form .ant-form-item {
  margin-bottom: 24rem;
}

.ant-form-item .ant-input:hover {
  background: transparent;
  border-color: rgba(34, 35, 40, 0.3);
}
.ant-form-item-with-help .ant-form-item-explain {
  margin-bottom: -24px;
}

.ant-form-item .ant-input:focus {
  background: transparent;
  border-color: rgba(34, 35, 40, 0.3);
  box-shadow: none !important;
}
.ant-form-item .ant-form-item-control-input {
  min-height: 32rem;
}
.ant-form .ant-form-item:last-child {
  margin-bottom: 0;
}
.ant-form-vertical {
  margin: auto 0;
}

/*隐藏滚动条*/
::-webkit-scrollbar {
  display: none;
}